import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { FileUpload } from '@entur/fileupload';
import { ImageIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { Heading4 } from '@entur/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [myFiles, setMyFiles] = React.useState([])\n  const [error, setError] = React.useState(false)\n  const onDelete = file => {\n    console.log(file)\n    setMyFiles(prev => prev.filter(f => f !== file))\n  }\n  const onDrop = React.useCallback(acceptedFiles => {\n    acceptedFiles.forEach(file => {\n      const reader = new FileReader()\n      reader.onerror = () => setError(true)\n      reader.onload = () => {\n        setMyFiles([...myFiles, ...acceptedFiles])\n        const binaryStr = reader.result\n        console.log(binaryStr)\n      }\n      reader.readAsArrayBuffer(file)\n    })\n  }, [])\n  return (\n    <FileUpload\n      accept=\"image/*\"\n      files={myFiles}\n      errorUpload={error}\n      onDrop={onDrop}\n      onDelete={file => onDelete(file)}\n    ></FileUpload>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      FileUpload,
      ImageIcon,
      Playground,
      PageHeader,
      ImportStatement,
      Heading4,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [myFiles, setMyFiles] = React.useState([]);
        const [error, setError] = React.useState(false);

        const onDelete = file => {
          console.log(file);
          setMyFiles(prev => prev.filter(f => f !== file));
        };

        const onDrop = React.useCallback(acceptedFiles => {
          acceptedFiles.forEach(file => {
            const reader = new FileReader();

            reader.onerror = () => setError(true);

            reader.onload = () => {
              setMyFiles([...myFiles, ...acceptedFiles]);
              const binaryStr = reader.result;
              console.log(binaryStr);
            };

            reader.readAsArrayBuffer(file);
          });
        }, []);
        return <FileUpload accept="image/*" files={myFiles} errorUpload={error} onDrop={onDrop} onDelete={file => onDelete(file)} mdxType="FileUpload"></FileUpload>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "fileupload"
    }}>{`FileUpload`}</h3>
    <ImportStatement imports="FileUpload" mdxType="ImportStatement" />
    <Props of={FileUpload} mdxType="Props" />
    <p>{`Bygger på react-dropzone, og vil spre props for den. Les mer om disse her: `}<a parentName="p" {...{
        "href": "https://react-dropzone.js.org/#src"
      }}>{`https://react-dropzone.js.org/#src`}</a></p>
    <p>{`Komponenten har ingen validering eller forkasting av potensielt skadelige filer. `}<strong parentName="p">{`Dette er noe brukeren selv må stå for`}</strong>{`.
Dette kan forenkles litt ved å bruke `}<inlineCode parentName="p">{`accept`}</inlineCode>{` propen, som begrenser hvilke filtyper som vil aksepteres.`}</p>
    <p>{`Les mer på `}<a parentName="p" {...{
        "href": "https://owasp.org/www-community/vulnerabilities/Unrestricted_File_Upload"
      }}>{`OWASP-artikkelen om filopplasting`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      